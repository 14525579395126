import React from 'react'
import '../card-tahap/cardtahap.css'

const CardTahap = ({title, body}) => {
  return (
    <div className="card-tahap">
        <div className="header">
            <div className="rounded-user">
                <i className="fa-solid fa-users"></i>
            </div>
            <div className="text-tahap">
                <h5>{title}</h5>
            </div>
        </div>
        <div className="body">
            <p>{body}</p>
        </div>
    </div>
  )
}

export default CardTahap