import React from 'react'
import { Parallax } from 'react-parallax';
import '../style.css'

const LearnMore = () => {
  return (
    <Parallax strength={200} bgImage='/img/ihms.jpg'>
        <section id='learn-more'>
            <div className="container">
            <h3>Be a Future Leader in <br /> Global Business and Hospitality</h3>
            <a href="https://ihmscampus.com/" target="_blank" rel="noopener noreferrer">
              <button className='btn'>Learn More</button>
            </a>
            </div>
        </section>
    </Parallax>
  )
}

export default LearnMore