import React from 'react'

const InfoBeasiswa = () => {
  return (
    <section id='info-beasiswa'>
        <div className="container">
        <h1>Arnantyo X IHMS Scholarship</h1>
        <p className='paragraph'>Kamu punya ambisi kuliah di luar negeri ? dan tertarik di bidang hospitality ? <br /> Jika iya, ini adalah peluang emas untukmu.  Arnantyo x IHMS Scholarship diberikan khusus bagi kamu yang memiliki semangat belajar & mimpi besar untuk sukses di bidang hospitality. Tersedia beasiswa mulai dari 10%, 20%, 30% hingga 100%.</p>
        <div className="beasiswa">
            <div className="row d-flex align-items-end">
                <div className="col-lg-3 col-md-6">
                    <div className="box-beasiswa">
                        <p className='p-beasiswa'>Beasiswa</p>
                        <h3>10%</h3>
                        <div className='jml-beasiswa'><span className='rp'>Rp.</span> <span className='nominal'>5.400.000</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="box-beasiswa">
                        <p className='p-beasiswa'>Beasiswa</p>
                        <h3>20%</h3>
                        <div className='jml-beasiswa'><span className='rp'>Rp.</span> <span className='nominal'>10.200.000</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="box-beasiswa">
                        <p className='p-beasiswa'>Beasiswa</p>
                        <h3>30%</h3>
                        <div className='jml-beasiswa'><span className='rp'>Rp.</span> <span className='nominal'>8.100.000</span></div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="box-beasiswa last">
                        <p className='p-beasiswa last'>Beasiswa</p>
                        <h3>100%</h3>
                        <div className='jml-beasiswa'><span className='rp last'>Rp.</span> <span className='nominal last'>27.000.000</span></div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-lg-4 col-md-4">
                    <video className='video-ihms' poster='/img/thumbnail1.jpg' controls>
                        <source src="/video/ihms.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <div className="col-lg-4 col-md-4">
                    <video className='video-ihms' poster='/img/thumbnail2.jpg' controls>
                        <source src="/video/ihms2.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <div className="col-lg-4 col-md-4">
                    <video className='video-ihms' poster='/img/thumbnail3.jpeg' controls>
                        <source src="/video/ihms3.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <div className="col-lg-4 col-md-4">
                    <video className='video-ihms' poster='/img/thumbnail4.jpeg' controls>
                        <source src="/video/ihms4.mp4" type="video/mp4"></source>
                    </video>
                </div>
                <div className="col-lg-4 col-md-4">
                    <video className='video-ihms' poster='/img/thumbnail5.jpeg' controls>
                        <source src="/video/ihms5.mp4" type="video/mp4"></source>
                    </video>
                </div>
            </div>
        </div>
        </div>
    </section>
  )
}

export default InfoBeasiswa
